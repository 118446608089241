import { Home } from "./home/Home";
import { LoginRedirect } from "./login/LoginRedirect";
import { ProcessForm } from "./process/ProcessForm";
import { ProcessStatus } from "./process/ProcessStatus";
import { TranslationsForm } from "./translations/TranslationsForm";
import { Search } from "./search/Search";
import { CandidateView } from "./candidate/CandidateView";
import { AccessDenied } from "./login/AccessDenied";
import { NotFound } from "./home/NotFound";
import { Dashboard } from "./analytics/Dashboard";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/redirect',
    element: <LoginRedirect />
  },
  {
    path: '/process/:id/edit',
    element: <ProcessForm />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR, process.env.REACT_APP_ROLE_CONSULTANT]
  },
  {
    path: '/process/:id/progress',
    element: <ProcessStatus />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR, process.env.REACT_APP_ROLE_CONSULTANT]
  },
  {
    path: '/process/new',
    element: <ProcessForm />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR, process.env.REACT_APP_ROLE_CONSULTANT]
  },
  {
    path: '/translations',
    element: <TranslationsForm />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR]
  },
  {
    path: '/search',
    element: <Search />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR, process.env.REACT_APP_ROLE_CONSULTANT]
  },
  {
    path: '/candidates/:id',
    element: <CandidateView />,
    exact: true,
    roles: [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR, process.env.REACT_APP_ROLE_CONSULTANT]
  },
  {
    path: '/accessDenied',
    element: <AccessDenied />
  },
  {
    path: '/notFound',
    element: <NotFound />
  },
  {
    path: '/analytics',
    element: <Dashboard />
  }
];

export default AppRoutes;
