import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProcessAnalyticsData } from "../../api";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ProcessOverviewChart } from "./ProcessOverviewChart";
import { StatsCard } from "./StatsCard";
import { DataFilters } from "./DataFilters";
import {
  formatAsLocalDateMonthAndYear,
  formatDateAsIsoDate,
  getFirstDayOfMonth,
  isEmptyJson
} from "../../utils/uiUtils";

export const MonthlyStatistics = () => {
  const { t } = useTranslation();

  const searchParameters = Object.freeze({
    fromDate: getFirstDayOfMonth(0),
    toDate: formatDateAsIsoDate(new Date())
  });
  const [searchData, setSearchData] = useState(searchParameters);

  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);

  const applyFilterChange = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const updateResults = (e) => {
    e.preventDefault();
    setData({});
    setDataLoading(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProcessAnalyticsData(searchData)
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.log(error);
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    if (dataLoading) {
      fetchData().catch(console.error);
    }
  }, [data, dataLoading, searchData]);

  const chartData = (monthlyStatistics) => {
    return monthlyStatistics.map(month => {
      return {
        created: formatAsLocalDateMonthAndYear(month.created, 'short'),
        draft: countByState(month.processes, 'Draft'),
        published: countByState(month.processes, 'Published'),
        archived: countByState(month.processes, 'Archived')
      };
    }).reverse();
  }

  const applicantStatData = (applicantStatistics) => {
    return [
      { name: t("Analytics.Overview.Applicants.Applicants"), count: applicantStatistics.applicants },
      { name: t("Analytics.Overview.Applicants.LoggedIn"), count: applicantStatistics.loggedIn },
      { name: t("Analytics.Overview.Applicants.Interviews"), count: applicantStatistics.interviews },
      { name: t("Analytics.Overview.Applicants.GroupSimulations"), count: applicantStatistics.groupSimulations }
    ]
  }

  const processesStatData = (processStatistics) => {
    return [
      { name: t("Process.State.Draft"), count: countByState(processStatistics, 'Draft') },
      { name: t("Process.State.Published"), count: countByState(processStatistics, 'Published') },
      { name: t("Process.State.Archived"), count: countByState(processStatistics, 'Archived') }
    ]
  }

  const countByState = (processes, state) => {
    const m =  processes.find((p) => p.state === state);
    return m?.count || 0;
  }

  return (
    <>
      <div className="container mx-auto space-y-4">
        <DataFilters
          data={searchData}
          updateFiltersAction={applyFilterChange}
          applyFiltersAction={updateResults}
        />
        {dataLoading ? (
          <div className="pt-3">
            <LoadingIndicator type="small"/>
          </div>
        ) : (
          <>
            <div className="flex w-full justify-between">
              {!isEmptyJson(data.overallStatistics) && (
                <div className="flex flex-col"><StatsCard data={processesStatData(data.overallStatistics)}/></div>
              )}
              {!isEmptyJson(data.applicantStatistics) && (
                <div className="flex flex-col"><StatsCard data={applicantStatData(data.applicantStatistics)}/></div>
              )}
            </div>
            <div className="flex w-full">
              <div className="flex flex-col space-y-3 text-sm w-1/5">
                {!isEmptyJson(data.monthlyStatistics) && data.monthlyStatistics.map((item, index) => (
                  <div key={`status_data_month_${index}_key`} className="w-full">
                    <div className="w-full font-semibold">{formatAsLocalDateMonthAndYear(item.created)}</div>
                    <ul className="w-full">
                      {item.processes.map((p, i) => (
                        <li key={`status_data_${i}_key`} className="space-x-2">
                          {t(`Process.State.${p.state}`)} {p.count}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="w-full text-sm align-middle">
                {!isEmptyJson(data.monthlyStatistics) && (
                  <ProcessOverviewChart data={chartData(data.monthlyStatistics)}/>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
