import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { HiddenField } from "../../components/HiddenField";
import { TextInput } from "../../components/TextInput";
import { SelectBox } from "../../components/SelectBox";
import { cloneCandidate } from "../CandidateUtils";
import { getCandidateAttachments } from "../../api";
import { ApplicantAttachments } from "../../process/components/ApplicantAttachments";
import Tabs from "../../components/Tabs";
import * as Yup from "yup";

export const CandidateCard = ({ candidateId, candidate }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [errors, setErrors] = useState({});

  const [attachmentsLoading, setAttachmentsLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);

  const Schema = Yup.object().shape({
    candidateId: Yup.string().optional(),
    email: Yup.string()
      .required(t("Process.Applicants.Validation.EmailRequired"))
      .email(t("Process.Applicants.Validation.EmailFormat")),
    phoneNumber: Yup.string().optional(),
    firstName: Yup.string().required(
      t("Process.Applicants.Validation.FirstNameRequired"),
    ),
    lastName: Yup.string().required(
      t("Process.Applicants.Validation.LastNameRequired"),
    ),
    preferredLanguage: Yup.string().required(
      t("Process.Applicants.Validation.LanguageCodeRequired"),
    ),
  });

  const [candidateData, updateCandidateData] = useState(
    cloneCandidate(candidate),
  );

  const tabs = [
    {
      name: t("Candidate.Tabs.Candidate"),
      current: activeTab === 1,
      index: 1,
    },
  ];

  const locales = [
    { id: "fi", name: t("Locales.Fi") },
    { id: "sv", name: t("Locales.Sv") },
    { id: "en", name: t("Locales.En") },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const selectedLanguage = () => {
    if (candidateData.preferredLanguage) {
      return locales.findIndex(
        (entry) => entry.id === candidateData.preferredLanguage,
      );
    }
    return 0;
  };

  const revalidate = (name, value) => {
    Yup.reach(Schema, name)
      .validate(value, {})
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      })
      .catch((error) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateCandidateData({ ...candidateData, [name]: value });
    revalidate(name, value);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCandidateAttachments(candidateId)
        .then((response) => {
          setAttachments(response);
        })
        .catch((error) => {
          console.log(error);
          setAttachments([]);
        })
        .finally(() => {
          setAttachmentsLoading(false);
        });
    };
    if (attachmentsLoading) {
      fetchData().catch(console.error);
    }
  }, [candidateId, attachments, attachmentsLoading]);

  return (
    <div className="col-span-1 gap-x-8 gap-y-10 border-b border-gray-900/10 bg-white shadow-sm ring-2 ring-gray-900/5 sm:rounded-xl w-full">
      <div className="w-full text-base flex items-center justify-between pl-4 pr-4 pt-4">
        <div className="flex items-start">
          <Tabs
            tabs={tabs}
            action={handleTabClick}
            showIcon={false}
            theme="pills"
          />
        </div>
        <div className="flex items-center space-x-4"></div>
      </div>
      {activeTab === 1 && (
        <div className="container mx-auto w-full mt-4 divide-y">
          <div className="pl-4 pr-4">
            <div className="flex mb-4 gap-x-2">
              <div className="w-1/2">
                <HiddenField
                  id={`candidateId`}
                  name={`candidateId`}
                  value={candidateData.candidateId}
                  action={handleChange}
                />
                <TextInput
                  label={t("Process.Properties.Email")}
                  id={`email`}
                  name={`email`}
                  placeholder={t("Process.Applicants.Placeholders.Email")}
                  value={candidateData.email}
                  action={handleChange}
                  required={true}
                  error={errors["email"]}
                />
              </div>
              <div className="w-1/2">
                <TextInput
                  label={t("Process.Properties.PhoneNumber")}
                  id={`phoneNumber`}
                  name={`phoneNumber`}
                  placeholder={t("Process.Applicants.Placeholders.PhoneNumber")}
                  value={candidateData.phoneNumber}
                  action={handleChange}
                  required={false}
                  error={errors["phoneNumber"]}
                />
              </div>
            </div>
            <div className="flex mb-4 gap-x-2">
              <div className="w-1/3">
                <TextInput
                  label={t("Process.Properties.FirstName")}
                  id={`firstName`}
                  name={`firstName`}
                  placeholder={t("Process.Applicants.Placeholders.FirstName")}
                  value={candidateData.firstName}
                  action={handleChange}
                  required={true}
                  error={errors["firstName"]}
                />
              </div>
              <div className="w-1/3">
                <TextInput
                  label={t("Process.Properties.LastName")}
                  id={`lastName`}
                  name={`lastName`}
                  placeholder={t("Process.Applicants.Placeholders.LastName")}
                  value={candidateData.lastName}
                  action={handleChange}
                  required={true}
                  error={errors["lastName"]}
                />
              </div>
              <div className="w-1/3">
                <SelectBox
                  id={`preferredLanguage`}
                  label={t("Process.Properties.Language")}
                  selectedOption={candidateData.preferredLanguage}
                  options={locales}
                  required={true}
                  error={errors["preferredLanguage"]}
                  action={handleChange}
                  selected={selectedLanguage()}
                />
              </div>
            </div>
          </div>
          <div className="flex mt-4 gap-x-2 p-4">
            <div className="min-w-0 flex-1 gap-y-2">
              <div className="flex text-sm text-gray-700 font-medium">
                {t("Candidate.Attachments")} ({t("Candidate.MaxAttachments")})
              </div>
              <div className="flex mb-4 gap-x-2 text-sm text-gray-700 mt-2">
                {!attachmentsLoading && (
                  <ApplicantAttachments attachments={attachments} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
