import { useTranslation } from "react-i18next";
import { ProcessListItem } from "./ProcessListItem";
import { isEmptyArray } from "../../utils/uiUtils";

export function ProcessesListView({ processes, searchTerm = '', refreshAction }) {
  const { t } = useTranslation();

  return (
    <>
      {!isEmptyArray(processes) && (
        <table className="min-w-full">
          <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-5 w-1/12">
              {t("Processes.List.State")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-4/12">
              {t("Processes.List.Description")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-3/12">
              {t("Processes.List.Consultant")}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-3/12">
              {t("Processes.List.Coordinator")}
            </th>
            <th scope="col" className="w-12"></th>
            <th scope="col" className="w-12"></th>
            <th scope="col" className="w-12"></th>
            <th scope="col" className="w-12"></th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-300 bg-white">
            {processes.map((item) => (
              <tr key={item.id}>
                <ProcessListItem
                  item={item}
                  searchTerm={searchTerm}
                  refreshAction={refreshAction}
                />
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
