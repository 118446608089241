export const GreenPill = ({ text, includeDot = false }) => {
  return (
    <span className="inline-flex items-center rounded-full bg-green-100 ml-2 px-2 py-1 text-xs font-normal text-green-700 whitespace-nowrap">
      {includeDot && (
        <svg
          className="h-1.5 w-1.5 fill-green-500 ml-0.5 mr-2"
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {text}
    </span>
  );
};

export const WarnPill = ({ text, includeDot = false }) => {
  return (
    <span className="inline-flex items-center rounded-full bg-yellow-100 ml-2 px-2 py-1 text-xs font-normal text-yellow-700 whitespace-nowrap">
      {includeDot && (
        <svg
          className="h-1.5 w-1.5 fill-yellow-500 ml-0.5 mr-2"
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {text}
    </span>
  );
};

export const ErrorPill = ({ text }) => {
  return (
    <span className="inline-flex items-center rounded-full bg-red-100 ml-2 px-2 py-1 font-normal text-xs text-red-700 whitespace-nowrap">
      {text}
    </span>
  );
};

export const GrayPill = ({ text, includeDot = false }) => {
  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600 whitespace-nowrap">
      {includeDot && (
        <svg
          className="h-1.5 w-1.5 fill-gray-400"
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {text}
    </span>
  );
};
