import React, { useEffect, useState, useRef } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getTranslations, saveTranslations } from "../api";
import { Provider } from "./components/Provider";
import { Button } from "../components/Button";
import { copyObject } from "../utils/uiUtils";
import { PageHeadingSmall } from "../components/PageHeadings";

export const TranslationsForm = () => {
  const { t } = useTranslation();
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changes, setChanges] = useState(false);
  const originalProviders = useRef();

  useEffect(() => {
    async function fetchTranslations() {
      if (!isLoading) {
        setIsLoading(true);

        const response = await getTranslations();
        originalProviders.current = copyObject(response);
        setProviders(response);

        setIsLoading(false);
        setChanges(false);
      }
    }
    fetchTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTranslations = async () => {
    if (!isLoading) {
      setIsLoading(true);

      const response = await saveTranslations(providers);
      originalProviders.current = copyObject(response);
      setProviders(response);

      setIsLoading(false);
      setChanges(false);
    }
  };

  const updateProvider = (providerId, provider) => {
    const index = providers.findIndex((p) => p.id === providerId);
    const updatedProviders = providers.map((p, i) => {
      return i === index ? provider : p;
    });

    setChanges(
      JSON.stringify(updatedProviders) !== JSON.stringify(originalProviders.current)
    );
    setProviders(updatedProviders);
  };

  return (
    <>
      <AuthenticatedTemplate>
        <div className="container mx-auto">
          <div className="flex justify-between mb-4">
            <PageHeadingSmall text={t("Translations.Title")} />
            <div className="flex align-middle justify-end">
              {changes && (
                <div className="flex align-middle pt-3">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-500"/>
                  <span className="text-xs text-red-500 pl-2">
                {t("Translations.UnsavedChanges")}
              </span>
                </div>
              )}
              <div className="pl-4">
                <Button
                  text={t("Translations.Save")}
                  action={updateTranslations}
                  isDisabled={!changes}
                />
              </div>
            </div>
          </div>

          {providers?.map((item) => (
            <Provider
              key={`provider_${item.id}`}
              provider={item}
              action={updateProvider}
            />
          ))}
        </div>
      </AuthenticatedTemplate>
    </>
  );
};
