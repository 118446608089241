import React from "react";
import { useTranslation}  from "react-i18next";
import { XAxis, Tooltip, CartesianGrid, Legend, YAxis, Bar, BarChart, ResponsiveContainer } from 'recharts';

export const ProcessOverviewChart = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={500} maxHeight={700}>
      <BarChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="created" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="draft" fill="#FFA300" name={t("Process.State.Draft")} />
        <Bar dataKey="published" fill="#00239C" name={t("Process.State.Published")} />
        <Bar dataKey="archived" fill="#DD0053" name={t("Process.State.Archived")} />
      </BarChart>
    </ResponsiveContainer>
  );
};
