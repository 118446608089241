import React from "react";
import { useTranslation } from "react-i18next";
import { minutesToDaysHoursAndMinutes } from "../../utils/uiUtils";

export const AverageTime = ({ averagesData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="text-sm font-semibold">{t("Analytics.Processes.Times.ResponseTimes")}</div>
        <p className="text-sm text-gray-900">{t("Analytics.Processes.Times.ProcessOpen")}: {minutesToDaysHoursAndMinutes(averagesData.averageTimeUntilLogin)}</p>
        <p className="text-sm text-gray-900">{t("Analytics.Processes.Times.Booking")}: {minutesToDaysHoursAndMinutes(averagesData.averageTimeUntilBooking)}</p>

        <div className="text-sm font-semibold">{t("Analytics.Processes.Times.LeadTime")}</div>
        <p className="text-sm text-gray-900">{t("Analytics.Processes.Times.LeadTime")}: {minutesToDaysHoursAndMinutes(averagesData.averageTimeUntilReportAdded)}</p>
      </div>
    </>
  );
};
