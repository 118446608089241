import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFilters } from "./DataFilters";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import {
  getFirstDayOfMonth,
  formatAsLocalDateTime,
  isEmptyArray,
  getLastDayOfMonth
} from "../../utils/uiUtils";
import { downloadProcessLog, getProcessLog } from "../../api";

export const ProcessLog = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [processes, setProcesses] = useState([]);

  const searchParameters = Object.freeze({
    fromDate: getFirstDayOfMonth(7),
    toDate: getLastDayOfMonth(6)
  });

  const [searchData, setSearchData] = useState(searchParameters);

  const applyFilterChange = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const updateResults = (e) => {
    e.preventDefault();
    setProcesses([]);
    setIsLoading(true);
  };

  const downloadResults = async (e) => {
    e.preventDefault();
    await downloadProcessLog(searchData);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProcessLog(searchData)
        .then((response) => {
          setProcesses(response);
        })
        .catch(() => {
          setProcesses([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (isLoading) {
      fetchData().catch(console.error);
    }
  }, [processes, isLoading, searchData]);

  return (
    <div className="md:flex md:items-center md:justify-between w-full">
      <div className="w-full">
        <DataFilters
          data={searchData}
          updateFiltersAction={applyFilterChange}
          applyFiltersAction={updateResults}
          downLoadAction={downloadResults}
        />
        <div
          className="flow-root border-b border-gray-900/10 pb-6 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-l sm:rounded-r p-4 w-full mt-4">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t("Analytics.ProcessLog.Customer")}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Analytics.ProcessLog.JobTitle")}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Analytics.ProcessLog.CreatedDate")}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Analytics.ProcessLog.Foreperson")}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("Analytics.ProcessLog.State")}
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {isLoading && (
                  <tr aria-colspan={6}>
                    <td colSpan={6} className="pt-10">
                      <LoadingIndicator type="small"/>{" "}
                      <span className="pl-3">{t("Overlay.Loading")}</span>
                    </td>
                  </tr>
                )}
                {!isLoading && isEmptyArray(processes) && (
                  <tr aria-colspan={6}>
                    <td colSpan={6} className="pt-10">
                        <span className="text-sm italic text-gray-900">
                          {t("Search.AssessmentLog.NoResults")}
                        </span>
                    </td>
                  </tr>
                )}
                { !isEmptyArray(processes) && processes.map((item, index) => (
                  <tr key={`process_${index}`}>
                    <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                      {item.customer}
                    </td>
                    <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                      {item.jobTitle}
                    </td>
                    <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                      {formatAsLocalDateTime(item.createdAt)}
                    </td>
                    <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                      {item.forepersonEmail}
                    </td>
                    <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                      {t(`Process.State.${item.processState}`)}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
