import { Fragment } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "../../components/Button";
import { isEmptyJson } from "../../utils/uiUtils";

export const AppointmentModal = ({ data, title, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const closeButtonClicked = (event) => {
    event.preventDefault();
    setOpen(false);
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-x-hidden overflow-y-auto min-w-screen h-[90vh] rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-2xl sm:p-6">
                <div className="sm:flex sm:flex-row mx-6 justify-between">
                  <h3 className="text-mps-ultramarine font-semibold">{title}</h3>
                  <Button
                    text={t("Actions.Close")}
                    action={closeButtonClicked}
                  />
                </div>
                <div>
                  {!isEmptyJson(data) && (
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            {t("Analytics.ProcessLog.Customer")}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("Analytics.ProcessLog.JobTitle")}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("Analytics.Processes.Appointments.Applicant")}
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            {t("Analytics.Processes.Appointments.InterviewType")}
                          </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {data.map((item, index) => (
                          <tr key={`appointment_details_data_${index}`}>
                            <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                              {item.customer}
                            </td>
                            <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                              {item.jobTitle}
                            </td>
                            <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                              {item.applicantName}
                            </td>
                            {item.interviewType === 'Office' ? (
                              <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                                {t("Process.Properties.Office")}
                              </td>
                            ): (
                              <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                                {item.interviewType}
                              </td>
                            )}
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
);
};
