import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { DataFilters } from "./DataFilters";
import { AppointmentCard } from "./AppointmentCard";
import { AverageTime } from "./AverageTime";
import {
  formatDateAsIsoDate,
  getDateAsISO,
  isEmptyJson
} from "../../utils/uiUtils";
import { getProcessStatistics } from "../../api";

export const ProcessStats = () => {
  const { t } = useTranslation();

  const searchParameters = Object.freeze({
    fromDate: getDateAsISO(7),
    toDate: formatDateAsIsoDate(new Date()),
    offices: '',
    consultants: '',
    assessmentLevels: '',
    serviceTypes: ''
  });
  const [searchData, setSearchData] = useState(searchParameters);

  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);

  const applyFilterChange = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const updateResults = (e) => {
    e.preventDefault();
    setData({});
    setDataLoading(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProcessStatistics(searchData)
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.log(error);
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    if (dataLoading) {
      fetchData().catch(console.error);
    }
  }, [data, dataLoading, searchData]);

  return (
    <>
      <div className="container mx-auto space-y-4">
        <DataFilters
          data={searchData}
          updateFiltersAction={applyFilterChange}
          applyFiltersAction={updateResults}
          includeOffices={true}
          includeConsultants={true}
          includeAssessmentLevel={true}
          includeServiceType={true}
        />
        {dataLoading ? (
          <div className="pt-3">
            <LoadingIndicator type="small"/>
          </div>
        ) : (
          <>
            <div>
              <div className="mt-4 space-y-2">
                <div className="text-sm font-semibold">{t("Processes.State.Published")}</div>
                <AppointmentCard data={data.appointments}/>
              </div>
            </div>
            <div>
              <div className="mt-4 space-y-2">
                <div className="">
                  <AverageTime averagesData={data.averageTimes}/>
                </div>
              </div>
            </div>
            <div className="flex w-full">
              <div className="flex flex-col space-y-3 text-sm w-full">
                <div className="font-semibold pb-2 w-full">{t("Analytics.Processes.PopularInterviewTimes")}</div>
                {isEmptyJson(data.popularInterviews) ? (
                  <div className="mt-2">{t("Analytics.Processes.NoDataMatches")}</div>
                ) : (
                  <ul>
                    {data.popularInterviews.slice(0, 5).map((item, index) => (
                      <li
                        key={`popular_times_${index}`}>{t(`Generic.Weekday.${item.dayOfWeek}`, item.dayOfWeek)} {item.time} ({item.count})</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
