import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PresentationChartBarIcon } from "@heroicons/react/20/solid";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { GrayPill, GreenPill, WarnPill } from "../../components/Pills";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { isBlank, isNotBlank } from "../../utils/uiUtils";
import { softDeleteProcess } from "../../api";

export function ProcessListItem({ item, searchTerm = '', refreshAction }) {
  const { t } = useTranslation();

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [ongoingDelete, setOngoingDelete] = useState(false);
  const [removalError, setRemovalError] = useState("");

  const handleSoftDelete = async () => {
    setRemovalError("");
    setOngoingDelete(true);
    await softDeleteProcess(item.id).then(() => {
      setShowConfirmDeleteDialog(false);
      if (refreshAction) {
        refreshAction();
      }
    }).catch((e) => {
      console.error(e);
      setRemovalError(t("Processes.SoftDelete.RemoveFailed"));
    }).finally(() => {
      setOngoingDelete(false);
    });
  }

  const openConfirmDialog = () => {
    setRemovalError("");
    setShowConfirmDeleteDialog(true);
  }

  const employeeName = (employees) => {
    if (isNotBlank(employees[0]?.lastName)) {
      return `${employees[0]?.lastName}, ${employees[0]?.firstName}`;
    }
    return employees[0]?.email;
  }

  const statusPageLink = (id) => {
    return isBlank(searchTerm)
      ? `/process/${id}/progress`
      :`/process/${id}/progress?q=${encodeURIComponent(searchTerm)}`
  }

  const editPageLink = (id) => {
    return isBlank(searchTerm)
      ? `/process/${id}/edit`
      :`/process/${id}/edit?q=${encodeURIComponent(searchTerm)}`
  }

  return (
    <>
      <td className="whitespace-nowrap text-sm w-32 px-2 py-3 ml-6 text-left">
        {item.processState === "Published" ? (
          <GreenPill text={t("Processes.List.Published")}/>
        ) : item.processState === "Draft" ? (
          <WarnPill text={t("Processes.List.Draft")}/>
        ) : item.processState === "Archived" ? (
          <GrayPill text={t("Processes.List.Archived")}/>
        ) : null}
      </td>
      <td
        className="whitespace-nowrap text-sm text-gray-900 sm:truncate truncate overflow-hidden overflow-ellipsis max-w-0">
        {item.jobTitle} | {item.customer.name}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 sm:truncate">
        {employeeName(item.consultants)}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 sm:truncate">
        {employeeName(item.coordinators)}
      </td>
      <td className="whitespace-nowrap items-center text-sm text-gray-900 sm:pr-0 w-12 min-w-12">
        {item.bookedApplicantsStatus && (
          <span>{item.bookedApplicantsStatus}</span>
        )}
      </td>
      <td className="relative whitespace-nowrapitems-centertext-sm font-medium sm:pr-0 w-12 min-w-12">
        {refreshAction && item.processState === "Archived" && (
          <>
            <TrashIcon
              className="h-5 w-5 mb-0.5 flex-shrink-0 text-mps-ultramarine hover:cursor-pointer"
              aria-hidden="true"
              onClick={openConfirmDialog}
            />
            <ConfirmDialog
              title={t("Processes.SoftDelete.Title")}
              content={t("Processes.SoftDelete.Description", {jobTitle: item.jobTitle, customer: item.customer.name})}
              confirmButtonLabel={t("Actions.Delete")}
              open={showConfirmDeleteDialog}
              setOpen={setShowConfirmDeleteDialog}
              action={() => handleSoftDelete(item.id)}
              ongoingAction={ongoingDelete}
              mode="confirm"
              errorMessage={removalError}
            />
          </>
        )}
      </td>
      <td className="relative whitespace-nowrapitems-centertext-sm font-medium sm:pr-0 w-12 min-w-12">
        {item.processState === "Published" || item.processState === "Archived" ? (
          <Link
            to={statusPageLink(item.id)}
            className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline"
          >
            <PresentationChartBarIcon
              className="h-5 w-5 flex-shrink-0 text-mps-ultramarine"
              aria-hidden="true"
              title={t("Processes.List.ProgressAndReports")}
            />
          </Link>
        ) : (
          <p className="text-gray-500">
            <PresentationChartBarIcon
              className="h-5 w-5 flex-shrink-0 text-gray-700e"
              aria-hidden="true"
            />
          </p>
        )}
      </td>
      <td className="relative whitespace-nowrap items-center text-sm font-medium sm:pr-0 w-12 min-w-12">
        <Link
          to={editPageLink(item.id)}
          className="font-medium text-mps-ultramarine dark:text-mps-ultramarine hover:underline pr-4"
        >
          <PencilIcon
            className="mt-4 h-5 w-5 flex-shrink-0 text-mps-ultramarine"
            aria-hidden="true"
            title={t("Processes.Actions.Edit")}
          />
        </Link>
      </td>
    </>
  );
}
