import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentPlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { PlayCircleIcon } from "@heroicons/react/24/solid";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { DynamicToggle } from "../../components/Toggle";
import { assessmentStatus, assessmentStatusColor, parenthesizeIfNotEmpty } from "../ProcessUtils";
import { classNames, isEmptyJson } from "../../utils/uiUtils";
import { continueTestRequest, reactivateTestRequest } from "../../api";
import AssessmentReport from "../../components/AssessmentReport";

export const ApplicantAssessmentStatusCard = ({ processId, applicantId, applicantName, assessment, actionsDisabled, setApplicantRefreshState, applicantRefreshAction, notificationUpdateAction, updateAssessmentAction }) => {
  const { t } = useTranslation();

  const [showConfirmReactivateDialog, setShowConfirmReactivateDialog] = useState(false);
  const [ongoingReactivate, setOngoingReactivate] = useState(false);

  const handleContinueTest = async (assessmentId) => {
    if (actionsDisabled) {
      return;
    }
    setOngoingReactivate(true);
    setApplicantRefreshState(true);
    await continueTestRequest(processId, applicantId, assessmentId)
      .then((response) => {
        if (response.requestAccepted) {
          applicantRefreshAction();
        } else {
          notificationUpdateAction(true, t("Errors.Title"), response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        notificationUpdateAction(true, t("Errors.Title"), "");
        setApplicantRefreshState(false);
      })
      .finally(() => {
        setShowConfirmReactivateDialog(false);
        setOngoingReactivate(false);
      });
  };

  const handleReactivateTest = async (event, assessmentId) => {
    event.preventDefault();
    if (actionsDisabled) {
      return;
    }
    setApplicantRefreshState(true);
    await reactivateTestRequest(processId, applicantId, assessmentId)
      .then((response) => {
        if (response.requestAccepted) {
          applicantRefreshAction();
        } else {
          notificationUpdateAction(true, t("Errors.Title"), response.error);
        }
      })
      .catch((error) => {
        console.log(error);
        notificationUpdateAction(true, t("Errors.Title"), "");
        setApplicantRefreshState(false);
      });
  }

  const fillAssessment = assessment.hideFromCandidate && assessment.status !== 'Completed' && assessment.status !== 'Disabled';

  return (
    <>
      <span className="">
        {assessment.provider}: {assessment.name}{" "}
        {parenthesizeIfNotEmpty(assessment.options)}:&nbsp;
        <span
          className={assessmentStatusColor(assessment.status)}
        >
          {t(assessmentStatus(assessment.status))}
        </span>
      </span>
      {assessment.continueAvailable && (
        <div className="lg:inline-flex text-left align-bottom">
          <PlayCircleIcon
            aria-hidden="true"
            title={t("Actions.ContinueTest")}
            onClick={() => setShowConfirmReactivateDialog(true)}
            className={classNames(
              actionsDisabled
                ? "text-gray-500"
                : "text-blue-400 hover:cursor-pointer",
              "ml-2 mb-0.5 h-5 w-5 flex-shrink-0",
            )}
          />
          <ConfirmDialog
            title={t("ProcessProgress.ContinueTestDialog.Title")}
            content={t("ProcessProgress.ContinueTestDialog.Description", {assessment: assessment.name, applicant: applicantName})}
            confirmButtonLabel={t("Actions.ContinueTest")}
            open={showConfirmReactivateDialog}
            setOpen={setShowConfirmReactivateDialog}
            action={() => handleContinueTest(assessment.id)}
            ongoingAction={ongoingReactivate}
            mode="confirm"
          />
        </div>
      )}
      {!assessment.integrated && (
        <div className="lg:inline-flex lg:float-right text-left pt-1">
          <DynamicToggle
            label={t("ProcessProgress.MarkAsCompleted")}
            value={assessment.status === "Completed"}
            action={(value) => {
              updateAssessmentAction(assessment.id, value);
            }}
          />
        </div>
      )}
      {fillAssessment && (
        <div className="text-sm font-medium mt-1 group flex items-start">
          <DocumentPlusIcon
            className="-ml-0.5 h-5 w-5 mr-1 pt-0.5"
            aria-hidden="true"
          />
          <a
            href={assessment.linkUrl}
            rel="noreferrer"
            target={"_blank"}
            className="text-mps-ultramarine underline mt-0.5 mb-1 ml-1"
          >
            {t("ProcessProgress.DoAssessment", {assessmentName: assessment.name.toLowerCase()})}
          </a>
        </div>
      )}
      {assessment.hideFromCandidate && assessment.status === 'Completed' && (
        <div className="text-sm font-medium mt-1 group flex items-start space-x-2">
          <PencilSquareIcon
            className="-ml-0.5 h-5 w-5 pt-0.5"
            aria-hidden="true"
          />
          <button
            className="text-sm text-mps-ultramarine underline hover:text-blue-800"
            onClick={(event) => handleReactivateTest(event, assessment.id)}
            disabled={actionsDisabled}
          >
            {t("ProcessProgress.ReactivateAssessment", {assessmentName: assessment.name.toLowerCase()})}
          </button>
        </div>
      )}
      {!isEmptyJson(assessment.reports) &&
        assessment.reports.map((report, index) => (
          <div key={`report_key_${assessment.id}_${applicantId}_${index}`}>
            <AssessmentReport report={report}/>
          </div>
        ))}
    </>
  );
};
