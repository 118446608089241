import React from "react";
import { classNames, isNotBlank } from "../../utils/uiUtils";

export const StatsCard = ({ title, data }) => {

  return (
    <div>
      { isNotBlank(title) && (
        <h3 className="text-base font-semibold text-gray-900">{title}</h3>
      )}
      <dl className={classNames("grid grid-cols-1 gap-5 sm:grid-cols-4")}>
        {data.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-3 py-4 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.count}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
