import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ProcessLog } from "./components/ProcessLog";
import { MonthlyStatistics } from "./components/MonthlyStatistics";
import { ProcessStats } from "./components/ProcessStats";
import Tabs from "../components/Tabs";

export const Dashboard = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(1);

  let tabs = [
    { name: t("Analytics.Tabs.Overview"), current: activeTab === 1, index: 1 },
    { name: t("Analytics.Tabs.Processes"), current: activeTab === 2, index: 2 },
    { name: t("Analytics.Tabs.ProcessLog"), current: activeTab === 3, index: 3 }
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <AuthenticatedTemplate>
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <div className="space-y-6">
              <Tabs tabs={tabs} action={handleTabClick} showIcon={false} />
              {activeTab === 1 && <MonthlyStatistics />}
              {activeTab === 2 && <ProcessStats />}
              {activeTab === 3 && <ProcessLog />}
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
    </>
  );
};
