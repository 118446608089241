import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { AppointmentModal } from "./AppointmentModal";
import { isEmptyArray, isEmptyJson } from "../../utils/uiUtils";

export const AppointmentCard = ({ data }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = (detailedDataType, title) => {
    setModalData(data.detailedStatistics[detailedDataType]);
    setShowModal(true);
    setModalTitle(title);
  }

  const closeModal = () => {
    setShowModal(false);
    setModalData([]);
    setModalTitle('');
  };

  return (
    <div className="">
      {!isEmptyJson(data) ? (
        <div
          className="flex flex-col border-b border-gray-900/10 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-4">
          <div className="text-sm flex items-start w-full whitespace-nowrap">
            {t("Analytics.Processes.BookedAppointments.Interview")}: {data.interviewBooked}
          </div>

          <div className="text-sm flex items-start w-full whitespace-nowrap">
            {t("Analytics.Processes.PendingAppointments.Interview")}: {data.interviewNotBooked}
            {data.interviewNotBooked > 0 && (
              <QuestionMarkCircleIcon
                aria-hidden="true"
                onClick={() => openModal("interviewNotBooked", t("Analytics.Processes.PendingAppointments.Interview"))}
                className="ml-2 mb-0.5 h-5 w-5 flex-shrink-0 hover:cursor-pointer text-mps-ultramarine"
              />
            )}
          </div>

          <div className="text-sm flex items-start w-full whitespace-nowrap">
            {t("Analytics.Processes.BookedAppointments.Group")}: {data.groupSimulationBooked}
          </div>

          <div className="text-sm flex items-start w-full whitespace-nowrap">
            {t("Analytics.Processes.PendingAppointments.Group")}: {data.groupSimulationNotBooked}
            {data.groupSimulationNotBooked > 0 && (
              <QuestionMarkCircleIcon
                aria-hidden="true"
                onClick={() => openModal("groupNotBooked", t("Analytics.Processes.PendingAppointments.Group"))}
                className="ml-2 mb-0.5 h-5 w-5 flex-shrink-0 hover:cursor-pointer text-mps-ultramarine"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-900">{t("Analytics.Processes.NoDataMatches")}</div>
      )}
      {showModal && !isEmptyArray(modalData) && (
        <AppointmentModal
          data={modalData}
          title={modalTitle}
          onClose={closeModal}
        />
      )}
    </div>
  );
};
