import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Button } from "../../components/Button";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ProcessesListView } from "../../process/components/ProcessesListView";
import { CandidatesListView } from "./CandidatesListView";
import { classNames, isEmptyArray, isNotBlank } from "../../utils/uiUtils";
import { textSearch } from "../../api";

export function TextSearch() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") ?? "");
  const [isLoading, setIsLoading] = useState(isNotBlank(searchTerm));
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const updateResults = () => {
    if (searchTerm.length >= 2) {
      setIsLoading(true);
    } else {
      setProcesses([]);
      setCandidates([]);
      setSearchCompleted(false);
    }
  };

  const updateSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const onFieldKeyDown = (e) => {
    if (e.keyCode === 13) {
      updateResults();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await textSearch(searchTerm)
        .then((response) => {
          setProcesses(response["processes"]);
          setCandidates(response["candidates"]);
          setSearchCompleted(true);
        })
        .catch((e) => {
          console.log(e);
          setSearchCompleted(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (isLoading) {
      fetchData().catch((e) => {
        console.error(e);
      });
    }
  }, [searchTerm, processes, candidates, isLoading, searchCompleted]);

  return (
    <AuthenticatedTemplate>
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex flex-row space-y-2 space-x-2">
          <div className="flex flex-col items-start">
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                id="text_search_field"
                name="text_search_field"
                placeholder={t("Search.TextSearch.Placeholder")}
                value={searchTerm}
                onChange={updateSearchTerm}
                onKeyDown={onFieldKeyDown}
                className={classNames(
                  "block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6",
                )}
              />
            </div>
          </div>
          <div className="flex flex-col items-start mt-1">
            <Button
              text={t("Search.TextSearch.ButtonLabel")}
              action={updateResults}
            />
          </div>
          <div className="flex flex-col items-start pt-2">
            {isLoading && (
              <div>
                <LoadingIndicator type="small" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          {searchCompleted > 0 && (
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight w-full">
              {t("Search.TextSearch.Processes")}
            </h2>
          )}
          <div className="w-full mt-4">
            <ProcessesListView
              processes={processes}
              searchTerm={searchTerm}
              refreshAction={updateResults}
            />
          </div>
          {isEmptyArray(processes) && searchCompleted > 0 && (
            <div className="text-left w-full text-gray-900 text-sm mt-4">
              {t("Search.TextSearch.NoResults")}
            </div>
          )}
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          {searchCompleted > 0 && (
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight w-full">
              {t("Search.TextSearch.Candidates")}
            </h2>
          )}
          <div className="w-full mt-4">
            <CandidatesListView
              candidates={candidates}
              searchTerm={searchTerm} />
          </div>
          {isEmptyArray(candidates) && searchCompleted > 0 && (
            <div className="text-left w-full text-gray-900 text-sm mt-4">
              {t("Search.TextSearch.NoResults")}
            </div>
          )}
        </div>
      </div>
    </AuthenticatedTemplate>
  );
}
