import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "../../components/DateRangePicker";
import { Button } from "../../components/Button";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { MultiSelectBox } from "../../components/MultiSelectBox";
import { getConsultants, getOffices } from "../../api";

export const DataFilters = ({ data, updateFiltersAction, applyFiltersAction, downLoadAction, includeOffices = false, includeConsultants = false, includeAssessmentLevel = false, includeServiceType = false }) => {
  const { t } = useTranslation();

  const [officesLoading, setOfficesLoading] = useState(includeOffices);
  const [offices, setOffices] = useState([]);

  const [consultantsLoading, setConsultantsLoading] = useState(includeConsultants);
  const [consultants, setConsultants] = useState([]);

  const assessmentLevels = [
    { id: "None", name: t("Process.Properties.None") },
    { id: "Manager", name: t("Process.Properties.Manager") },
    { id: "Expert", name: t("Process.Properties.Expert")},
    { id: "Foreperson", name: t("Process.Properties.Foreperson")},
    { id: "Official", name: t("Process.Properties.Official")}
  ];

  const serviceTypes = [
    { id: "None", name: t("Process.Properties.None") },
    { id: "PersonalAssessment", name: t("Process.Properties.PersonalAssessment") },
    { id: "NotificationSearch", name: t("Process.Properties.NotificationSearch")},
    { id: "DirectSearch", name: t("Process.Properties.DirectSearch")},
    { id: "Opc", name: t("Process.Properties.OPC")},
    { id: "OtherService", name: t("Process.Properties.OtherService")},
    { id: "CombinationSearch", name: t("Process.Properties.CombinationSearch")}
  ];

  const dateFiltersChange = (name, value) => {
    updateFiltersAction(name, value);
  };

  const handleSelectChange = (selectedItems) => {
    updateFiltersAction("offices", selectedItems);
  };

  const handleConsultantsChange = (selectedItems) => {
    updateFiltersAction("consultants", selectedItems);
  };

  const handleAssessmentLevelsChange = (selectedItems) => {
    updateFiltersAction("assessmentLevels", selectedItems);
  };

  const handleServiceTypesChange = (selectedItems) => {
    updateFiltersAction("serviceTypes", selectedItems);
  }

  useEffect(() => {
    const fetchData = async () => {
      await getOffices()
        .then((response) => {
          setOffices(response);
        })
        .catch((error) => {
          console.log(error);
          setOffices([]);
        })
        .finally(() => {
          setOfficesLoading(false);
        });
    };
    if (officesLoading) {
      fetchData().catch(console.error);
    }
  }, [offices, officesLoading]);

  useEffect(() => {
    const fetchData = async () => {
      await getConsultants()
        .then((response) => {
          setConsultants(response);
        })
        .catch((error) => {
          console.log(error);
          setConsultants([]);
        })
        .finally(() => {
          setConsultantsLoading(false);
        });
    };
    if (consultantsLoading) {
      fetchData().catch(console.error);
    }
  }, [consultants, consultantsLoading]);

  return (
    <div className="inline-block min-w-full align-middle">
      <div className="flex items-center justify-between">
        <div className="flex content-start">
          <DateRangePicker
            startDateValue={data.fromDate}
            startDateName="fromDate"
            endDateValue={data.toDate}
            endDateName="toDate"
            updateAction={dateFiltersChange}
          />
          {includeOffices && (
            <>
              {officesLoading && (
                <div className="w-48 ml-4 mb-1">
                  <LoadingIndicator type="small"/>{" "}
                  <span className="pl-3">{t("Overlay.Loading")}</span>
                </div>
              )}
              {offices && offices.length > 0 && (
                <div className="w-48 ml-4 mb-1">
                  <MultiSelectBox
                    id={`offices_filter`}
                    options={offices || []}
                    required={false}
                    action={handleSelectChange}
                    selectedItems={data.offices}
                    nothingSelectedLabel={t(
                      "Search.AssessmentLog.Filters.AllOffices"
                    )}
                  />
                </div>
              )}
            </>
          )}
          {includeConsultants && (
            <>
              {consultantsLoading && (
                <div className="w-48 ml-4 mb-1">
                  <LoadingIndicator type="small"/>{" "}
                  <span className="pl-3">{t("Overlay.Loading")}</span>
                </div>
              )}
              {consultants && consultants.length > 0 && (
                <div className="w-48 ml-4 mb-1">
                  <MultiSelectBox
                    id={`consultants_filter`}
                    options={consultants || []}
                    required={false}
                    action={handleConsultantsChange}
                    selectedItems={data.consultants}
                    nothingSelectedLabel={t(
                      "Analytics.Processes.AllConsultants"
                    )}
                  />
                </div>
              )}
            </>
          )}
          {includeAssessmentLevel && (
            <div className="w-48 ml-4 mb-1">
              <MultiSelectBox
                id={`assessment_level_filter`}
                options={assessmentLevels || []}
                required={false}
                action={handleAssessmentLevelsChange}
                selectedItems={data.assessmentLevels}
                nothingSelectedLabel={t(
                  "Analytics.Processes.AllAssessmentLevels"
                )}
              />
            </div>
          )}
          {includeServiceType && (
            <div className="w-48 ml-4 mb-1">
              <MultiSelectBox
                id={`assessment_level_filter`}
                options={serviceTypes || []}
                required={false}
                action={handleServiceTypesChange}
                selectedItems={data.serviceTypes}
                nothingSelectedLabel={t(
                  "Analytics.Processes.AllServiceTypes"
                )}
              />
            </div>
          )}
        </div>

        <div className="flex content-end">
          <div className="col-span-full space-x-4">
            <Button
              text={t("Search.AssessmentLog.Filters.Update")}
              icon={"UpdateIcon"}
              type="secondary"
              action={(event) => {
                applyFiltersAction(event);
              }}
            />
            {downLoadAction && (
              <Button
                text={t("Search.AssessmentLog.Filters.DownloadCsv")}
                icon={"DownloadIcon"}
                type="secondary"
                action={(event) => downLoadAction(event)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
